.map-incident-dialog, .map-incident-dialog .container {
    font-size: 1rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.map-incident-dialog p > span {
    font-weight: 500;
    margin-right: 6px;
}

.map-incident-dialog p > .info-source {
    text-transform: capitalize;
    font-weight: lighter;
}

.map-incident-dialog label {
    font-weight: bold;
}

.grid-container {
    display: grid;
    grid-template-columns: 10em 20em; /* First column is 1em wide, second column is 2em wide */
    gap: 0.5em 1em; /* Adjust gap between grid items as needed */
}

.grid-spacer {
    grid-column: span 2 / span 2;
}

.grid-header {
    font-weight: bold;
    text-align: left;
}

.grid-header-full-width {
    font-weight: bold;
    text-align: left;
    grid-column: span 2 / span 2;

}

.grid-text {
    /* Define styles for grid items */
}

.button {
    background-color: rgb(38 153 251);
    padding: 0.5em 1em;
    text-align: center;
    border-radius: 0.375rem;
    color: #ffffff;
    display: block;
}

.button:hover {
    opacity: 0.8;
}

.map-incident-dialog select:focus, .map-incident-dialog select:focus-visible {
    outline: none;
}

.map-incident-dialog select {
    margin: 0.5em 0;
    background-color: #ffffff;
}

.map-incident-dialog select option {
    padding: 1em;
    background-color: #ffffff;
}